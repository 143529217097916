.gantt_task_cell.day_end,
.gantt_task_cell.no_work_hour.day_start {
  border-right-color: #c7dfff;
}

.gantt_task_cell.week_end.day_end,
.gantt_task_cell.week_end.day_start {
  border-right-color: #e2e1e1;
}

.gantt_task_cell.week_end,
.gantt_task_cell.no_work_hour {
  background-color: #f5f5f5;
}

.gantt_task_row.gantt_selected .gantt_task_cell.week_end {
  background-color: #f8ec9c;
}

#plupload_plupload .list-unstyled {
  max-height: 200px;
  overflow-y: scroll;
  background: #eee;
  padding: 10px;
}

#theme-settings {
  display: none;
}

.long-word-wrap {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

#plupload_plupload button {
  display: inline-block;
  font-weight: 400;
  color: #4e5155;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.338rem 0.925rem;
  font-size: 0.894rem;
  line-height: 1.54;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  border-color: transparent;
  background: #259ddc;
  color: #fff;
  margin-right: 5px;
}

.default-style .sidenav .app-brand.demo {
  height: 66px;
}

#theme-settings .theme-settings-open-btn {
  top: auto;
  bottom: 5px;
  opacity: 0.1;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.app-brand-logo.demo {
  display: flex;
  height: 34px;
  align-items: center;
  justify-content: center;
}

.app-brand-mark.demo {
  display: none;
  height: 34px;
  margin-left: 12px;
  margin-top: -4px;
}

.modal:nth-of-type(even) {
  z-index: 1089 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1088 !important;
}

.filepond--item {
  width: calc(50% - 0.5em);
}

.filepond--root {
  max-height: 270px;
}

.gripper {
  background-image: radial-gradient(#ddd 33%, transparent 33%);
  background-size: 3px 3px;
  border-radius: 3px;
}

.job-item-selected {
  background: #e2edf5;
}

.job-item-handle:hover {
  background: #e2edf5;
}

.job-item-selected .job-item-handle {
  background: #bbccd8;
}

.opacity-0 {
  opacity: 0;
}

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas)
    .app-brand-logo.demo {
    display: none;
  }

  .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas)
    .app-brand-mark.demo {
    display: flex;
  }
}

.app-brand-logo.demo svg {
  width: 20px;
  height: 11px;
}

.app-brand-text.demo {
  font-size: 1.1rem;
}

.custom-checkbox .custom-control-label {
  cursor: pointer;
}

.selectedcount {
  font-size: 12px;
  margin-left: 10px;
  font-weight: normal;
}

.btn.btn-action,
.btn.btn-action.disabled,
.btn.btn-action.disabled:hover,
.btn.btn-action.disabled:focus,
.btn.btn-action[disabled],
.btn.btn-action[disabled]:hover,
.btn.btn-action[disabled]:focus {
  color: #fff;
  background: #e65100;
  border-color: #e65100;
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active,
.btn-action.active,
.btn-action.active.focus,
.btn-action.active:focus,
.btn-action.active:hover,
.btn-action:active.focus,
.btn-action:active:focus,
.btn-action:active:hover,
.show > .btn-action.dropdown-toggle,
.open > .dropdown-toggle.btn-action,
.open > .dropdown-toggle.btn-action:hover,
.open > .dropdown-toggle.btn-action:focus,
.btn-action:not(:disabled):not(.disabled).active,
.btn-action:not(:disabled):not(.disabled):active {
  background: #c74600;
  border-color: #c74600;
}

.editsection {
  border-bottom: 1px solid #eee;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.proserve-table .ReactTable {
  border-left: 0;
  border-right: 0;
}

.proserve-table .ReactTable .rt-thead {
  border-bottom: 0;
}

.proserve-table .ReactTable .rt-td {
  cursor: pointer;
}

.proserve-table .ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.proserve-table .ReactTable .rt-noData {
  top: 90px;
}

.scrollarea .scrollbar-container.vertical {
  width: 7px;
}

.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 4px;
  background: #fff;
}

#stripoSettingsContainer .nav {
  display: block;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-control-label::before {
  background-color: #eee;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}

@media (min-width: 992px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none;
  }
}

/* *****************************************************************************
 * Navbar
 */

.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: 0.5rem;
    width: 22rem;
  }

  .demo-navbar-user .dropdown-menu {
    margin-top: 0.25rem;
  }
}

/* *****************************************************************************
 * Content
 */

.demo-vertical-spacing > * + * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-sm > * + * {
  margin-top: 0.9375rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-lg > * + * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}

.demo-inline-spacing > *,
.demo-paragraph-spacing > p > * {
  margin: 0 0.375rem 0.9375rem 0 !important;
}

.demo-paragraph-spacing > p {
  margin: 0;
}

.demo-blocks > * {
  display: block !important;
}

html:not(.material-style) .material-style-only {
  display: none !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir="rtl"] .rtl-only {
  display: block !important;
}

#steps {
  list-style: none;
  padding: 0;
  max-width: 100%;
  position: relative;
  margin-top: 10px;
}

#steps li {
  display: block;
}

#steps .block {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  background: #2785b7;
  border-radius: 10px;
  border: 5px solid #fff;
  cursor: pointer;
  box-shadow: 0px 2px 1px #e2e2e2;
}

#steps .block.active {
  border: 5px solid #bbbbbb;
}

#steps .block::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -15px;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #2785b7;
  clear: both;
}

#steps .block.firstblock {
  border-bottom: solid 5px #2785b7;
}

#steps .block.firstblock h4,
#steps .block.firstblock p,
#steps .block.firstblock li {
  color: #000;
}

#steps .block.lastblock h4,
#steps .block.lastblock p,
#steps .block.lastblock li {
  color: #000;
}

#steps .block.firstblock::after {
  margin-top: 3px;
  border-top: 15px solid #2785b7;
}

#steps .block.lastblock::after {
  border: none;
}

#steps .block-content {
  width: 100%;
  padding: 15px;
  color: #fff;
}

#steps p,
#steps h4 {
  margin-bottom: 3px;
  color: #fff;
}

#steps .next-icon {
  width: 32px;
  height: 32px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAA3NCSVQICAjb4U/g AAAACXBIWXMAAARuAAAEbgHQo7JoAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2Nh cGUub3Jnm+48GgAAAB5QTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA how4rQAAAAl0Uk5TAAwREiowWMPOLoJhMgAAAEhJREFUKFNjYBhCgCUNDgLAAoyV M6FghgFEiThMoBmqB6YEpgCuBKYApgShAKoEoQCiBFkBWAmyApASVAVAJagKgEoU GIYaAADIMSWmfFk62AAAAABJRU5ErkJggg==")
    no-repeat 50% 0;
  display: block;
  position: absolute;
  left: 25%;
  margin-left: -16px;
}

#steps .campaign-requirements {
  list-style-type: disc;
}
#steps .campaign-requirements li {
  display: list-item;
}

#steps .cardtitle {
  color: #fff;
  margin-bottom: 8px;
}

#addsteps .block-content {
  width: 100%;
  padding: 15px;
  color: #fff;
}

#addsteps .addblock {
  width: 100%;
  margin-bottom: 0px;
  position: relative;
  background: #2785b7;
  border-radius: 10px;
  cursor: pointer;
}

#addsteps .addblock::after {
  position: absolute;
  left: 50%;
  margin-left: -15px;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #2785b7;
  clear: both;
}

p.smsPreview {
  max-width: 255px;
  word-wrap: break-word;
  margin-bottom: 12px;
  line-height: 24px;
  position: relative;
  padding: 10px 20px;
  border-radius: 25px;
}

.smsContainer {
  padding: 26px;
  border: 1px solid #ddd;
}

.smsPreview {
  font-family: "Arial";
  font-size: 15px;
  font-weight: normal;
  max-width: 450px;
  display: flex;
  flex-direction: column;
}

.smsPreview p:before,
.smsPreview p:after {
  content: "";
  position: absolute;
  bottom: -2px;
  height: 20px;
}

.from-me {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}

.from-me:before {
  right: -7px;
  border-right: 20px solid #0b93f6;
  border-bottom-left-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.from-me:after {
  right: -56px;
  width: 26px;
  background: white;
  border-bottom-left-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
  transform: translate(-30px, -2px);
}
.from-them {
  background: #e5e5ea;
  color: black;
}
.from-them:before {
  left: -7px;
  border-left: 20px solid #e5e5ea;
  border-bottom-right-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
}
.from-them:after {
  left: 4px;
  width: 26px;
  background: white;
  border-bottom-right-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
  transform: translate(-30px, -2px);
}

/* height and width helpers */

.height-full {
  height: 100% !important;
}
.height-0 {
  height: 0 !important;
}
.height-10 {
  height: 10px !important;
}
.height-20 {
  height: 20px !important;
}
.height-30 {
  height: 30px !important;
}
.height-35 {
  height: 35px !important;
}
.height-40 {
  height: 40px !important;
}
.height-50 {
  height: 50px !important;
}
.height-60 {
  height: 60px !important;
}
.height-70 {
  height: 70px !important;
}
.height-80 {
  height: 80px !important;
}
.height-90 {
  height: 90px !important;
}
.height-100 {
  height: 100px !important;
}
.height-150 {
  height: 150px !important;
}
.height-200 {
  height: 200px !important;
}
.height-250 {
  height: 250px !important;
}
.height-300 {
  height: 300px !important;
}
.height-350 {
  height: 350px !important;
}
.height-400 {
  height: 400px !important;
}
.height-450 {
  height: 450px !important;
}
.height-500 {
  height: 500px !important;
}
.height-550 {
  height: 550px !important;
}
.height-600 {
  height: 600px !important;
}
.width-xs {
  width: 150px !important;
}
.width-sm {
  width: 300px !important;
}
.width-md {
  width: 450px !important;
}
.width-lg {
  width: 600px !important;
}
.width-full {
  width: 100% !important;
}
.width-0 {
  width: 0 !important;
}
.width-10 {
  width: 10px !important;
}
.width-20 {
  width: 20px !important;
}
.width-30 {
  width: 30px !important;
}
.width-40 {
  width: 40px !important;
}
.width-50 {
  width: 50px !important;
}
.width-60 {
  width: 60px !important;
}
.width-70 {
  width: 70px !important;
}
.width-80 {
  width: 80px !important;
}
.width-90 {
  width: 90px !important;
}
.width-100 {
  width: 100px !important;
}
.width-150 {
  width: 150px !important;
}
.width-200 {
  width: 200px !important;
}
.width-250 {
  width: 250px !important;
}
.width-300 {
  width: 300px !important;
}
.width-350 {
  width: 350px !important;
}
.width-400 {
  width: 400px !important;
}
.width-450 {
  width: 450px !important;
}
.width-500 {
  width: 500px !important;
}
.width-550 {
  width: 550px !important;
}
.width-600 {
  width: 600px !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 1px !important;
}
.m-2 {
  margin: 2px !important;
}
.m-3 {
  margin: 3px !important;
}
.m-4 {
  margin: 4px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-35 {
  margin: 35px !important;
}
.m-40 {
  margin: 40px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-1 {
  margin-top: 1px !important;
}
.m-t-2 {
  margin-top: 2px !important;
}
.m-t-3 {
  margin-top: 3px !important;
}
.m-t-4 {
  margin-top: 4px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.m-r-1 {
  margin-right: 1px !important;
}
.m-r-2 {
  margin-right: 2px !important;
}
.m-r-3 {
  margin-right: 3px !important;
}
.m-r-4 {
  margin-right: 4px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-r-35 {
  margin-right: 35px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-1 {
  margin-bottom: 1px !important;
}
.m-b-2 {
  margin-bottom: 2px !important;
}
.m-b-3 {
  margin-bottom: 3px !important;
}
.m-b-4 {
  margin-bottom: 4px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-l-1 {
  margin-left: 1px !important;
}
.m-l-2 {
  margin-left: 2px !important;
}
.m-l-3 {
  margin-left: 3px !important;
}
.m-l-4 {
  margin-left: 4px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 1px !important;
}
.p-2 {
  padding: 2px !important;
}
.p-3 {
  padding: 3px !important;
}
.p-4 {
  padding: 4px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.wrapper {
  padding: 15px;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-t-1 {
  padding-top: 1px !important;
}
.p-t-2 {
  padding-top: 2px !important;
}
.p-t-3 {
  padding-top: 3px !important;
}
.p-t-4 {
  padding-top: 4px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-r-1 {
  padding-right: 1px !important;
}
.p-r-2 {
  padding-right: 2px !important;
}
.p-r-3 {
  padding-right: 3px !important;
}
.p-r-4 {
  padding-right: 4px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-1 {
  padding-bottom: 1px !important;
}
.p-b-2 {
  padding-bottom: 2px !important;
}
.p-b-3 {
  padding-bottom: 3px !important;
}
.p-b-4 {
  padding-bottom: 4px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-l-1 {
  padding-left: 1px !important;
}
.p-l-2 {
  padding-left: 2px !important;
}
.p-l-3 {
  padding-left: 3px !important;
}
.p-l-4 {
  padding-left: 4px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.f-s-0 {
  font-size: 0 !important;
}
.f-s-1 {
  font-size: 1px !important;
}
.f-s-2 {
  font-size: 2px !important;
}
.f-s-3 {
  font-size: 3px !important;
}
.f-s-4 {
  font-size: 4px !important;
}
.f-s-5 {
  font-size: 5px !important;
}
.f-s-6 {
  font-size: 6px !important;
}
.f-s-7 {
  font-size: 7px !important;
}
.f-s-8 {
  font-size: 8px !important;
}
.f-s-9 {
  font-size: 9px !important;
}
.f-s-10 {
  font-size: 10px !important;
}
.f-s-11 {
  font-size: 11px !important;
}
.f-s-12 {
  font-size: 12px !important;
}
.f-s-13 {
  font-size: 13px !important;
}
.f-s-14 {
  font-size: 14px !important;
}
.f-s-15 {
  font-size: 15px !important;
}
.f-s-16 {
  font-size: 16px !important;
}
.f-s-17 {
  font-size: 17px !important;
}
.f-s-18 {
  font-size: 18px !important;
}
.f-s-19 {
  font-size: 19px !important;
}
.f-s-20 {
  font-size: 20px !important;
}
.l-h-0 {
  line-height: 0 !important;
}
.l-h-1 {
  line-height: 1px !important;
}
.l-h-2 {
  line-height: 2px !important;
}
.l-h-3 {
  line-height: 3px !important;
}
.l-h-4 {
  line-height: 4px !important;
}
.l-h-5 {
  line-height: 5px !important;
}
.l-h-6 {
  line-height: 6px !important;
}
.l-h-7 {
  line-height: 7px !important;
}
.l-h-8 {
  line-height: 8px !important;
}
.l-h-9 {
  line-height: 9px !important;
}
.l-h-10 {
  line-height: 10px !important;
}
.l-h-11 {
  line-height: 11px !important;
}
.l-h-12 {
  line-height: 12px !important;
}
.l-h-13 {
  line-height: 13px !important;
}
.l-h-14 {
  line-height: 14px !important;
}
.l-h-15 {
  line-height: 15px !important;
}
.l-h-16 {
  line-height: 16px !important;
}
.l-h-17 {
  line-height: 17px !important;
}
.l-h-18 {
  line-height: 18px !important;
}
.l-h-19 {
  line-height: 19px !important;
}
.l-h-20 {
  line-height: 20px !important;
}
.l-h-21 {
  line-height: 21px !important;
}
.l-h-22 {
  line-height: 22px !important;
}
.l-h-23 {
  line-height: 23px !important;
}
.l-h-24 {
  line-height: 24px !important;
}
.l-h-25 {
  line-height: 25px !important;
}
.l-h-26 {
  line-height: 26px !important;
}
.l-h-27 {
  line-height: 27px !important;
}
.l-h-28 {
  line-height: 28px !important;
}
.l-h-29 {
  line-height: 29px !important;
}
.l-h-30 {
  line-height: 30px !important;
}
.t-plus-1 {
  top: 1px !important;
}
.t-plus-2 {
  top: 2px !important;
}
.t-plus-3 {
  top: 3px !important;
}
.t-plus-4 {
  top: 4px !important;
}
.t-plus-5 {
  top: 5px !important;
}
.t-plus-6 {
  top: 6px !important;
}
.t-plus-7 {
  top: 7px !important;
}
.t-plus-8 {
  top: 8px !important;
}
.t-plus-9 {
  top: 9px !important;
}
.t-plus-10 {
  top: 10px !important;
}
.t-minus-1 {
  top: -1px !important;
}
.t-minus-2 {
  top: -2px !important;
}
.t-minus-3 {
  top: -3px !important;
}
.t-minus-4 {
  top: -4px !important;
}
.t-minus-5 {
  top: -5px !important;
}
.t-minus-6 {
  top: -6px !important;
}
.t-minus-7 {
  top: -7px !important;
}
.t-minus-8 {
  top: -8px !important;
}
.t-minus-9 {
  top: -9px !important;
}
.t-minus-10 {
  top: -10px !important;
}
.l-plus-1 {
  left: 1px !important;
}
.l-plus-2 {
  left: 2px !important;
}
.l-plus-3 {
  left: 3px !important;
}
.l-plus-4 {
  left: 4px !important;
}
.l-plus-5 {
  left: 5px !important;
}
.l-plus-6 {
  left: 6px !important;
}
.l-plus-7 {
  left: 7px !important;
}
.l-plus-8 {
  left: 8px !important;
}
.l-plus-9 {
  left: 9px !important;
}
.l-plus-10 {
  left: 10px !important;
}
.l-minus-1 {
  left: -1px !important;
}
.l-minus-2 {
  left: -2px !important;
}
.l-minus-3 {
  left: -3px !important;
}
.l-minus-4 {
  left: -4px !important;
}
.l-minus-5 {
  left: -5px !important;
}
.l-minus-6 {
  left: -6px !important;
}
.l-minus-7 {
  left: -7px !important;
}
.l-minus-8 {
  left: -8px !important;
}
.l-minus-9 {
  left: -9px !important;
}
.l-minus-10 {
  position: relative !important;
  left: -10px !important;
}
.r-plus-1 {
  right: 1px !important;
}
.r-plus-2 {
  right: 2px !important;
}
.r-plus-3 {
  right: 3px !important;
}
.r-plus-4 {
  right: 4px !important;
}
.r-plus-5 {
  right: 5px !important;
}
.r-plus-6 {
  right: 6px !important;
}
.r-plus-7 {
  right: 7px !important;
}
.r-plus-8 {
  right: 8px !important;
}
.r-plus-9 {
  right: 9px !important;
}
.r-plus-10 {
  right: 10px !important;
}
.r-minus-1 {
  right: -1px !important;
}
.r-minus-2 {
  right: -2px !important;
}
.r-minus-3 {
  right: -3px !important;
}
.r-minus-4 {
  right: -4px !important;
}
.r-minus-5 {
  right: -5px !important;
}
.r-minus-6 {
  right: -6px !important;
}
.r-minus-7 {
  right: -7px !important;
}
.r-minus-8 {
  right: -8px !important;
}
.r-minus-9 {
  right: -9px !important;
}
.r-minus-10 {
  right: -10px !important;
}
.b-plus-1 {
  bottom: 1px !important;
}
.b-plus-2 {
  bottom: 2px !important;
}
.b-plus-3 {
  bottom: 3px !important;
}
.b-plus-4 {
  bottom: 4px !important;
}
.b-plus-5 {
  bottom: 5px !important;
}
.b-plus-6 {
  bottom: 6px !important;
}
.b-plus-7 {
  bottom: 7px !important;
}
.b-plus-8 {
  bottom: 8px !important;
}
.b-plus-9 {
  bottom: 9px !important;
}
.b-plus-10 {
  bottom: 10px !important;
}
.b-minus-1 {
  bottom: -1px !important;
}
.b-minus-2 {
  bottom: -2px !important;
}
.b-minus-3 {
  bottom: -3px !important;
}
.b-minus-4 {
  bottom: -4px !important;
}
.b-minus-5 {
  bottom: -5px !important;
}
.b-minus-6 {
  bottom: -6px !important;
}
.b-minus-7 {
  bottom: -7px !important;
}
.b-minus-8 {
  bottom: -8px !important;
}
.b-minus-9 {
  bottom: -9px !important;
}
.b-minus-10 {
  bottom: -10px !important;
}
